import { createGlobalStyle } from 'styled-components';

import './App.css';

const GlobalStyles = createGlobalStyle`
  html {
    --basis-height: calc(100vh / 5);
    --font-size-lg: calc(var(--basis-height) * 0.314);
    --font-size-md: calc(var(--basis-height) * 0.2);
    --font-size-sm: calc(var(--basis-height) * 0.15);
    --font-size-xs: calc(var(--basis-height) * 0.1);
    --theme-yellow: #DCFF04
  }
`;

function App() {
  return (
    <>
      <GlobalStyles />
      <div className="App container">
        <div></div>
        
        <section className="content">
          <a className="logo" href="//a61.io">A61</a>
          <p className="text_font-size-lg text__uppercase">
            The website is under development
          </p>  
        </section>
      
        <section className="content content__condensed">
          <p className="text_font-size-lg text__uppercase">
            El sitio web está en desarrollo
          </p>
          <p className="text_font-size-lg text__uppercase">
            Сайт находится в разработке
          </p>
        </section>     
     
        <section className="content">
          <a className="email text_font-size-md content_item_bottom text__uppercase" href="mailto:info@a61.io">info@a61.io</a>
        </section>
        
        <div></div>
      </div>
    </>
  );
}

export default App;
